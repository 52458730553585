@import '../../styles/common';

SECTION.blk-spotlight {
  @include mobile {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  margin-bottom: 100px;
  margin-top: 100px;
  .title-block {
    font-size: convert-rem(31);
    color: #45443f;
    text-transform: uppercase;
    text-align: center;
    padding: 1.5em 0 1.5em;
    font-weight: 400;
    letter-spacing: 0.1em;
    padding-top: 0;
    padding-bottom: 1em !important;
    @include mobile {
      font-size: 1.3rem;
      padding: 0;

      &:has(+ .spotlight-style-3) {
        padding-bottom: 3em;
      }
    }
  }
  IMG {
    width: 100%;
    display: block;
    object-fit: contain;
    height: 100%;
  }
  SPAN.sale-inf {
    width: 65px;
    height: 65px;
    background: #e3c886;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 20px;
    font-weight: $font-weight-normal;
    position: absolute;
    right: 1em;
    top: -1em;
    z-index: 2;
    @include mobile {
      width: 40px;
      height: 40px;
      font-size: convert-rem(14);
    }
  }
  DIV.content {
    position: relative;
    height: 100%;
    // display: block;
    // justify-content: center;
    // align-items: center;
    padding: 1em 1.5em;
    overflow: hidden;
    @include mobile {
      min-height: 11em;
    }
    &::after {
      content: url('../../images/block-video/bg-video-block.webp');
      position: absolute;
      bottom: -0.5em;
      left: 0;
      right: 0;
      text-align: center;
      opacity: 0.6;
    }
    .title-box {
      margin-bottom: 16px;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include font-size(18px);
      @include tablet {
        @include font-size(16px);
      }
      &__brand-name {
        font-weight: $font-weight-normal;
        color: $secondary_color;
        letter-spacing: normal;
        text-align: left;
      }
      &__product-name {
        font-size: inherit;
        font-weight: $font-weight-normal;
        color: $primary_color;
        padding-bottom: 5px;
        letter-spacing: normal;
        text-align: left;
      }
      &__title-block {
        font-weight: $font-weight-normal;
        color: $secondary_color;
        text-align: left;
      }
      &__title-block.bottom {
        text-align: left;
        position: absolute;
        bottom: 0;
        font-weight: $font-weight-normal;
        color: $secondary_color;
        width: 100%;
      }
      &__price,
      .discount-price {
        @include font-size(16px);
        @include tablet {
          @include font-size(14px);
        }
        font-weight: $font-weight-semi-bold;
        color: $primary_color;
      }
      &__variant {
        @include font-size(13px);
        font-weight: $font-weight-semi-bold;
        color: $secondary_color;
      }
    }
    .inf {
      // text-align: center;
      color: $primary_color;
      position: relative;
      z-index: 1;
      letter-spacing: 0.1em;
      text-align: left;
      @include font-size(15px);
      // P {
      //   font-size: convert-rem(14);
      //   text-transform: uppercase;
      //   font-weight: $font-weight-normal;
      // }
      H2 {
        line-height: 1.2em;
        font-weight: $font-weight-light;
        font-size: convert-rem(18);
        // color: $primary_color;
        text-transform: uppercase;
      }
      .button-box {
        margin-top: 14px;
        text-align: left;
        &__link {
          padding: 0.9em 2.4em;
          border: 1.7px $primary_color solid;
          color: $primary_color;
          font-weight: $font-weight-semi-bold;
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .shadow-box {
    box-shadow: 3px 4px 15px #e8e7e7;
    //box-shadow: 0px 10px 15px 1px grey;
  }
  SECTION.spotlight-style {
    // margin-bottom: 6em;
    @include mobile {
      margin-bottom: 2em;
    }
    position: relative;
    z-index: 1;
    //@include mobile {
    //> div {
    // margin-bottom: 1em;
    //}
    //}
  }

  SECTION.spotlight-style-1 {
    margin-right: 0;
    margin-left: 0;
    // padding-top: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    a {
      padding: 0;
    }
    > .col,
    > [class*='col-'] {
      padding-right: 0;
      padding-left: 0;
      max-height: 19em;
      overflow: hidden;
    }
    IMG {
      object-fit: cover;
    }
    DIV.img-product {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // max-width: 45%;
      // min-width: 35%;
      width: 50%;
      height: 100%;
      z-index: 30000;
      @include mobile {
        width: 70%;
        height: 100%;
      }
      picture {
        height: 100%;
        img {
          margin: 0 auto;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .no-content {
      width: 100%;
      img {
        @include mobile {
          height: auto;
        }
        height: 18rem;
        width: 100%;
      }
    }
  }
  SECTION.spotlight-style-2 {
    @include mobile {
      gap: 20px;
    }
    margin-top: 20px;
    margin-bottom: 20px;

    //& > div {
    // margin-bottom: 20px;
    //}

    .content-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      height: 100%;

      &.bottom {
        .spot_single_block_title {
          position: absolute;
          top: unset;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 30000;
        }
      }

      .spot_single_block_title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 30000;
        font-size: 35px;
        font-weight: 100;
      }
    }
    .wrapper-content {
      position: relative;
      display: flex;
      flex-flow: column;
      height: 100%;
      &.no-content {
        PICTURE {
          height: 100%;
        }
        IMG {
          @include mobile {
            height: auto;
          }
          height: 100%;
        }
      }
    }
    img {
      height: 18rem;
      object-fit: cover;
    }
    .content {
      position: unset;
      // padding: 2em 0 1em;
      &::after {
        content: '';
      }
      @include mobile {
        // padding: 3em 0 1em;
        margin-bottom: 2em;
      }
    }
    .sale-inf {
      right: 2em;
      top: -1em;
    }
    .img-product {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);
      z-index: 30000;
      width: 60%;
      z-index: 30000;
      img {
        width: 100%;
        display: block;
        object-fit: contain;
        margin: 0 auto;
      }
    }
  }

  SECTION.spotlight-style-3 {
    // padding-top: 80px;
    margin-top: 20px;
    margin-bottom: 20px;
    @include mobile {
      gap: 10px;
    }

    .img-product {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      width: 100%;
      top: 0em;
      z-index: 30000;
      picture {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 220px;
          height: 220px;
          object-fit: contain;
          top: 2em;
          @include desktop_small {
            width: 150px;
            height: 150px;
            top: 2rem;
          }

          @include mobile {
            width: 150px;
            height: 150px;
            top: 2rem;
          }
          @include tablet {
            width: 320px;
            height: 320px;
            top: 5rem;
          }
        }
      }
    }
    .wrapper-content {
      position: relative;
      @include mobile {
        // margin-bottom: 2em;
        margin-bottom: 10px;
      }
    }
    .content {
      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        //justify-content: center;
      }
      position: unset;
      // padding: 2em 0 0.5em;
      &::after {
        content: '';
        position: absolute;
        bottom: 0em;
        left: 0;
        background-image: url('../../images/block-video/bg-video-block.webp');
        right: 0;
        text-align: center;
        opacity: 0.7;
        height: 100%;
        width: 100%;
        background-size: 38%;
        background-position: bottom;
        background-repeat: no-repeat;
      }
      @include mobile {
        height: min-content;
        // padding: 1em 0 0;
        min-height: unset;
      }
    }
    .sale-inf {
      right: 2em;
      top: -1em;
    }
    .inf {
      h2 {
        // color: $black;
        line-height: 1.2em;
        @include mobile {
          @include font-size(15px);
        }
      }
      // p {
      //   color: $primary_color;
      // }
    }
    .section-square {
      width: 100%;

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
      img {
        position: absolute;
        object-fit: cover;
      }
      .content {
        position: absolute;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        bottom: 0;
        width: 100%;

        z-index: 1;

        .title-box__title-block {
          color: black;
          font-weight: 500;
          left: 0;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  section.spotlight-style-4 {
    margin-top: 0;
    padding-top: 0;

    .spotlight-style-4-grid {
      @include mobile_tablet {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 50px;
    }

    .spotlight-style-8-grid {
      @include mobile_tablet {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 5px;
      }
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 10px;
    }

    .spotlight-style-4-item {
      .content {
        @include mobile_tablet {
          font-size: 13px;
          letter-spacing: normal;
        }
      }

      height: auto;
    }

    .swiper {
      @include mobile_tablet {
        padding-bottom: 10px;
      }
      margin-top: 0;
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 0;
      }
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      color: #e3c886;
    }

    & > .my-swiper-button-next {
      @include mobile {
        right: 0px;
      }
      @include tablet {
        right: -20px;
      }
      right: -50px;
      width: 50px;
    }
    & > .my-swiper-button-prev {
      @include mobile {
        left: 0px;
      }
      @include tablet {
        left: -20px;
      }
      left: -50px;
      width: 50px;
    }
  }

  section.spotlight-style-4-icon {
    margin-top: 0;
    padding-top: 0;

    .spotlight-style-4-grid {
      @include mobile_tablet {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 50px;
    }

    .spotlight-style-4-item {
      .content {
        @include mobile_tablet {
          font-size: 13px;
          letter-spacing: normal;
        }
      }

      height: auto;
    }

    .swiper {
      @include mobile_tablet {
        padding-bottom: 10px;
      }
      padding-top: 60px;
      padding-bottom: 20px;
      margin-top: 0;
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 0;
      }
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      color: #e3c886;
    }

    & > .my-swiper-button-next {
      @include mobile {
        right: 0px;
      }
      @include tablet {
        right: -20px;
      }
      right: -50px;
      width: 50px;
    }
    & > .my-swiper-button-prev {
      @include mobile {
        left: 0px;
      }
      @include tablet {
        left: -20px;
      }
      left: -50px;
      width: 50px;
    }
  }

  .dark-theme {
    .content {
      background: $primary_color !important;
    }
    // .inf > h2 {
    //   color: $white !important;
    //   p {
    //     color: $white !important;
    //   }
    // }
    .title-box {
      &__product-name {
        color: $white !important;
      }
      &__price,
      .discount-price {
        color: $white !important;
      }
      .sale-price {
        color: $white;
      }
    }
    .inf {
      color: $white !important;
      text-align: center;
      .button-box {
        &__link {
          border: 1.7px $secondary_color solid !important;
          color: $secondary_color !important;
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .sale-price {
    letter-spacing: normal !important;
    text-align: center;
    display: block;
  }

  .spot_single_block_title {
    text-align: center;
    padding: 20px;
    font-size: 35px;
    font-weight: 100;
    @include mobile {
      font-size: 1.3rem;
    }
  }

  .spotlightv1-title,
  .spotlightv2-title,
  .spotlightv3-title {
    position: absolute;
    top: 0px;
    margin-top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1;
    text-align: center;
    letter-spacing: normal;
    line-height: 0.8;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    padding-bottom: 10px;
    &.bottom {
      top: unset;
      bottom: 0px;
      margin-bottom: 0px;
    }
    &.center {
      top: 35%;
      bottom: 0px;
      margin-bottom: 0px;
    }
    & > H3 {
      @include mobile {
        font-size: 18px;
      }
    }
    @include mobile {
      font-size: 16px;
    }
  }

  .spotlight-icon {
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0px;
    height: 70px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile {
      padding-left: 30px;
      padding-right: 30px;
    }
    .swiper {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
      margin: 0px !important;
      @include mobile {
        height: 70px !important;
      }
    }

    .swiper-scrollbar {
      display: none !important;
    }

    .wrapper-content {
      border: 1px solid grey;
      padding-left: 5px;
      padding-right: 5px;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      color: #e3c886 !important;
      font-size: 15px !important;
    }
    & > .my-swiper-button-prev {
      @include mobile {
        left: 0px;
        top: 15%;
        // display: none;
      }
      @include tablet {
        left: -20px;
      }
      left: -50px;
      width: 30px !important;
      height: 30px !important;
      top: 33.33px;
      background-color: whitesmoke;
      border-radius: 50%;
      opacity: 0.8;
      padding: 5px;
    }

    & > .my-swiper-button-next {
      @include mobile {
        right: 0px;
        top: 15%;
        //display: none;
      }
      @include tablet {
        right: -20px;
      }
      right: -50px;
      width: 50px;
      width: 30px !important;
      height: 30px !important;
      top: 33.33px;
      background-color: whitesmoke;
      border-radius: 50%;
      opacity: 0.8;
      padding: 5px;
    }
    .section-square {
      padding: 0px;
      margin: 0px;
      width: 100%;
      height: 90px;
      max-width: 99%;
      @include mobile {
        height: 50px;
      }

      &:after {
        content: '';
        display: block;
        //padding-bottom: 100%;
      }
      img {
        padding-left: 10px;
        padding-right: 10px;
        //position: absolute;
        object-fit: contain;
        @include mobile {
          padding-left: 2px;
          padding-right: 2px;
        }
      }
      .content {
        //position: absolute;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        bottom: 0;
        width: 33%;
        border: 1px solid grey;

        z-index: 1;

        .title-box__title-block {
          color: black;
          font-weight: 500;
          left: 0;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  .spotlightv2-title {
    z-index: 30000;
  }

  .spotlight-description {
    font-size: medium;
    //line-height: 80%;
    margin-bottom: 10px;
    @include mobile {
      font-size: small;
    }
  }

  .strike-through {
    text-decoration: line-through;
    margin-right: 1em;
  }

  h2.spotlightv3-title,
  h2.spotlightv2-title,
  h2.spotlightv1-title {
    font-size: 20px;
    font-weight: 100;
  }

  .perfume-advisor-button {
    position: absolute;
    z-index: 10001;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .button-box {
      margin-top: 14px;
      text-align: left;
      &__link {
        padding: 0.9em 2.4em;
        border: 1.7px $primary_color solid;
        color: $primary_color;
        font-weight: $font-weight-semi-bold;
        margin-right: 5px;
        margin-bottom: 5px;
        transition: all 300ms ease;

        &:hover {
          background: $primary_color;
          color: white;
        }
      }
    }
  }
}
